<!-- Mobile side bar -->
<template>
  <WCSidebar role="complementary">
    <!-- All sidebar components wrapped for sizing -->
    <div class="wc-sidebar">
      <div class="pt-3 d-flex flex-column h-100">
        <!-- START : Customer Details -->
        <div class="menu__text--color user-name--padding">
          <h2 class="mb-0 font-size-base" :aria-label="$t('name')">
            {{ $t('salutation') }}, {{ capitalizeFirstLetter(name) }}
          </h2>
          <div v-if="cusID" class="mt-2 font-size-xs" :aria-label="$t('customerId')">
            {{ $t('memberID') }}: {{ cusID }}
          </div>
        </div>
        <!-- END : Customer Details -->

        <!-- START : Shop by category -->
        <div
          class="side-menu-nav"
          v-if="
            isNotAnEmployee &&
              ((menu && menu.children) || (circularMenu && circularMenu.length)) &&
              !isScanAndGoMode
          "
        >
          <WCSideMenuCollapsibleTemplate>
            <template v-slot:menuHeader>
              <h3 class="mb-0 menu__text--color">{{ $t('shopByCategory') }}</h3>
            </template>
            <template v-slot:menuBody>
              <WCItemGroupCollapsibleMenu :circularMenu="circularMenu" :menu="menu"
            /></template>
          </WCSideMenuCollapsibleTemplate>
        </div>
        <!-- END : Shop by category -->

        <!-- START : Quick Links -->
        <div class="side-menu-nav">
          <ul class="side-menu-list">
            <WCSideMenuItem>
              <button
                v-if="isCustomer || isEmployee"
                class="btn btn-link p-0 w-100 text-left"
                @click="logout"
              >
                <font-awesome-icon class="side-menu-icon" icon="right-to-bracket" /><span
                  class="icon-menu--margin menu__text--color underline-link-from-center"
                  >{{ isCustomer ? $t('signOut') : $t('logOut') }}</span
                >
              </button>
              <template v-else>
                <font-awesome-icon class="side-menu-icon mr-2" icon="right-to-bracket" />
                <button class="btn btn-link underline-link-from-center m-0" @click="login">
                  <span class="menu__text--color">{{ $t('signIn') }}</span>
                </button>
                <span
                  class="mx-2 font-weight-bold font-size-sm"
                  v-if="$configuration.allowCustomerAdd"
                  >|</span
                >
                <button
                  class="btn btn-link underline-link-from-center m-0"
                  v-if="$configuration.allowCustomerAdd"
                  @click="register"
                >
                  <span class="menu__text--color"> {{ $t('register') }}</span>
                </button>
              </template>
            </WCSideMenuItem>
            <WCSideMenuItem v-if="isScanAndGoMode || this.$route.query.scan === 'true'">
              <button class="btn btn-link p-0 w-100 text-left" @click="onClickExitScanAndGoMode">
                <font-awesome-icon class="side-menu-icon" icon="barcode-read" /><span
                  class="icon-menu--margin menu__text--color underline-link-from-center"
                  >{{ $t('exitAcceleratedCheckOutMode') }}</span
                >
              </button>
            </WCSideMenuItem>
            <template v-if="isNotAnEmployee">
              <WCSideMenuItem v-for="menu in quickLinks" :key="menu.menuName"
                ><router-link :to="menu.menuRoute" class="">
                  <font-awesome-icon class="side-menu-icon" :icon="menu.menuIcon" />
                  <span
                    class="icon-menu--margin menu__text--color underline-link-from-center"
                    :data-testid="`side-menu-${menu.menuName}`"
                    >{{ menu.menuName }}</span
                  >
                </router-link></WCSideMenuItem
              >
            </template>
          </ul>
        </div>
        <!-- END : Quick Links -->

        <!-- START : Quick links information Custom Sources -->
        <div
          class="side-menu-nav"
          v-if="
            $configuration.customSources &&
              $configuration.customSources.length > 0 &&
              isNotAnEmployee
          "
        >
          <WCInformationCollapsibleMenu />
        </div>
        <!-- END : Quick links information -->

        <!-- START : Employee actions menu only for employee -->
        <div class="side-menu-nav" v-if="isEmployee">
          <WCEmployeeActionsCollapsibleMenu />
        </div>
        <!-- END : Employee actions menu only for employee -->

        <!-- START : Languages menu -->
        <div class="side-menu-nav" v-if="$configuration.i18nEnabled">
          <WCLanguagesCollapsibleMenu />
        </div>
        <!-- END : Languages menu -->

        <!-- START : Signin/Signout option -->
        <div class="side-menu-nav">
          <ul class="side-menu-list">
            <WCSideMenuItem v-if="!isEmployee && !isScanAndGoMode">
              <router-link class="menu__text--color" to="/employee/login">
                <font-awesome-icon class="side-menu-icon" icon="door-open" />
                <span class="icon-menu--margin underline-link-from-center">{{
                  $t('employeeLogin')
                }}</span>
              </router-link>
            </WCSideMenuItem>
            <WCSideMenuItem v-if="isDeleteEnabled" @click="openDeleteAccountModal">
              <div class="text-danger" data-testid="deleteAccountButton">
                <font-awesome-icon class="side-menu-icon" icon="trash" />
                <span class="icon-menu--margin underline-link-from-center">{{
                  $t('deleteAccount')
                }}</span>
              </div>
            </WCSideMenuItem>
          </ul>
        </div>
        <!-- END : Signin/Signout option -->

        <!-- START: Social links section -->
        <div class="mt-auto" v-if="$configuration.socialNetworksEnabled">
          <ul class="d-flex justify-content-center mt-5 social-icons unstyled-list">
            <li v-for="(socialProvider, si) in $configuration.socialProviders" :key="si">
              <a
                v-if="socialProvider.provider === 'facebook'"
                :href="`https://www.facebook.com/${socialProvider.userKey}`"
                :aria-label="$t('facebook')"
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon :icon="['fab', 'facebook-f']" class="facebook-icon"
              /></a>
              <a
                v-if="socialProvider.provider === 'twitter'"
                :href="`https://x.com/${socialProvider.userKey}`"
                :aria-label="$t('twitter')"
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon :icon="['fab', 'x-twitter']" class="twitter-icon" />
              </a>
              <a
                v-if="socialProvider.provider === 'youtube'"
                :href="`https://youtube.com/${socialProvider.userKey}`"
                :aria-label="$t('youTube')"
                target="_blank"
                rel="noopener"
                ><font-awesome-icon :icon="['fab', 'youtube']" class="youtube-icon" />
              </a>
              <a
                v-if="socialProvider.provider === 'linkedin'"
                :href="`https://www.linkedin.com/company/${socialProvider.userKey}`"
                :aria-label="$t('linkedIn')"
                target="_blank"
                rel="noopener"
                ><font-awesome-icon :icon="['fab', 'linkedin-in']" class="linkedin-icon" />
              </a>
              <a
                v-if="socialProvider.provider === 'instagram'"
                :href="`https://www.instagram.com/${socialProvider.userKey}`"
                :aria-label="$t('instagram')"
                target="_blank"
                rel="noopener"
                ><font-awesome-icon :icon="['fab', 'instagram']" class="instagram-icon" />
              </a>
            </li>
          </ul>
          <div class="font-size-xs text-center mb-3">
            <font-awesome-icon :icon="['far', 'copyright']" :title="$t('copyright')" />
            <span> {{ $t('allRightsReserved', { year: currentYear }) }} </span>
          </div>
        </div>
        <!-- END: Social links section -->
      </div>
      <!-- END: Sidebar wrapper for sizing -->
    </div>
  </WCSidebar>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import UserMixin from '@/modules/user/mixins/UserMixin';
import ContactUsMixin from '@/mixins/ContactUsMixin';
import NameFormatMixin from '@/mixins/NameFormatMixin';
import WCSidebar from '@/modules/sidebar/components/WCSideBar/WCSideBar.vue';
import WCLanguagesCollapsibleMenu from '@/modules/sidebar/components/WCLanguagesCollapsibleMenu/WCLanguagesCollapsibleMenu.vue';
import WCItemGroupCollapsibleMenu from '@/modules/sidebar/components/WCItemGroupCollapsibleMenu/WCItemGroupCollapsibleMenu.vue';
import WCSideMenuCollapsibleTemplate from '@/modules/sidebar/components/WCSideMenuCollapsibleTemplate/WCSideMenuCollapsibleTemplate.vue';
import WCEmployeeActionsCollapsibleMenu from '@/modules/sidebar/components/WCEmployeeActionsCollapsibleMenu/WCEmployeeActionsCollapsibleMenu.vue';
import WCInformationCollapsibleMenu from '@/modules/sidebar/components/WCInformationCollapsibleMenu/WCInformationCollapsibleMenu.vue';
import WCSideMenuItem from '@/modules/sidebar/components/WCSideMenuItem/WCSideMenuItem.vue';
import { USER_ROLES } from '@/constants/AppConstants';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import ScanAndGoMixin from '@/modules/user/mixins/ScanAndGoMixin';
import { capitalizeFirstLetter } from '@/utils/formatters';
import GiftCardMixin from '@/modules/giftcard/mixins/GiftCardMixin';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import AccountDeleteMixin from '@/modules/user/mixins/AccountDeleteMixin';

export default {
  components: {
    WCSidebar,
    WCLanguagesCollapsibleMenu,
    WCItemGroupCollapsibleMenu,
    WCSideMenuCollapsibleTemplate,
    WCEmployeeActionsCollapsibleMenu,
    WCSideMenuItem,
    WCInformationCollapsibleMenu,
  },
  /**
   * Created hook to fetch circular data for specials and promotions
   */
  async created() {
    await this.getCircularData();
  },
  methods: {
    capitalizeFirstLetter,
    login() {
      this.$router.push({ name: 'Login' });
    },
    register() {
      this.$router.push({ name: 'Register' });
    },
    /**
     * Method to fetch circulars for specials and promotions
     */
    async getCircularData() {
      try {
        const response = await axios.get('/api/circulars');
        this.circularMenu = response?.data?.data;
      } catch (error) {
        console.error(error);
      }
    },

    async onClickExitScanAndGoMode() {
      const query = { ...this.$route.query };
      if (query.scan === 'true') {
        query.scan = 'false';
        this.$router.replace({ query });
      }
      try {
        if (this.isScanAndGoMode) {
          await this.exitScanAndGoMode();
          this.$router.push({ name: 'Home' });
        }
      } catch (e) {
        console.error(e);
      }
    },
    filterQuickLinks(QUICK_LINKS) {
      return QUICK_LINKS.filter(link => {
        let showAccountsMenu = true;
        if (link.unauthorizedUsers) {
          if (this.isEmployee && this.isCustomer) {
            showAccountsMenu = !link.unauthorizedUsers.includes(USER_ROLES.EMPLOYEE_AS_CUSTOMER);
          } else if (this.isEmployee) {
            showAccountsMenu = !link.unauthorizedUsers.includes(USER_ROLES.EMPLOYEE);
          } else if (this.isCustomer) {
            showAccountsMenu = !link.unauthorizedUsers.includes(USER_ROLES.CUSTOMER);
          } else {
            showAccountsMenu = !link.unauthorizedUsers.includes(USER_ROLES.GUEST);
          }
        }
        return showAccountsMenu;
      });
    },
  },
  mixins: [
    UserMixin,
    ContactUsMixin,
    NameFormatMixin,
    EmployeeMixin,
    ScanAndGoMixin,
    GiftCardMixin,
    OrderTypesMixin,
    AccountDeleteMixin,
  ], // mixin for logout() and username
  data() {
    return {
      circularMenu: [],
    };
  },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isEmployee: 'user/isEmployee',
      isScanAndGoMode: 'user/isCustomerModeScan',
    }),
    cusID() {
      return this.user?.num;
    },
    /**
     * Method to return current year
     */
    currentYear() {
      return new Date().getFullYear();
    },
    contactUsEnabled() {
      return !this.isScanAndGoMode && this.showContactUsPage;
    },
    customerGroupsEnabled() {
      return (
        this.$configuration.customerGroups?.length > 0 &&
        this.$configuration.customerGroups.filter(el => el.groups?.length > 0).length > 0
      );
    },
    buyItAgainEnabled() {
      return this.$configuration.pastItemsEnabled && !this.isScanAndGoMode;
    },
    egiftCardsEnabled() {
      return this.$configuration.allowEGiftCards && !this.isScanAndGoMode;
    },

    /**
     * Construct quicklinks based on the unauthorized users list in the QUICK_LINKS array
     */
    quickLinks() {
      // Quick links menu
      const QUICK_LINKS = [
        {
          menuName: this.$t('accountSettings'),
          menuIcon: 'user',
          menuRoute: '/me/edit',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        },
      ];

      if (this.contactUsEnabled) {
        QUICK_LINKS.push({
          menuName: this.$t('contactUs'),
          menuIcon: 'phone',
          menuRoute: '/contact',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.customerGroupsEnabled) {
        QUICK_LINKS.unshift({
          menuName: this.$t('customerPrograms'),
          menuIcon: 'badge-check',
          menuRoute: '/me/programs',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.customerCardEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('scanCustomerCard'),
          menuIcon: 'id-card',
          menuRoute: '/me/card',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.rewardsEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('rewards'),
          menuIcon: 'star',
          menuRoute: '/me/rewards',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.listEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('shoppingLists'),
          menuIcon: 'list-check',
          menuRoute: '/me/lists',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.walletEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('wallet'),
          menuIcon: 'wallet',
          menuRoute: '/me/wallet',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.includeCircularLinkInMenu) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.circularLinkText,
          menuIcon: 'tags',
          menuRoute: '/circular',
        });
      }

      if (this.buyItAgainEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('buyItAgain'),
          menuIcon: 'bag-shopping',
          menuRoute: '/me/items',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }
      if (this.$configuration.pastOrdersEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('orderHistory'),
          menuIcon: 'clock-rotate-left',
          menuRoute: '/me/orders',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.allowCouponClipping) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('digitalCoupons'),
          menuIcon: 'scissors',
          menuRoute: '/cpb',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.egiftCardsEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('giftCards'),
          menuIcon: 'gift',
          menuRoute: this.giftCardUrl,
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      if (this.$configuration.cmsEnabled) {
        QUICK_LINKS.splice(1, 0, {
          menuName: this.$t('membershipDetails'),
          menuIcon: 'users',
          menuRoute: '/me/coop',
          unauthorizedUsers: [USER_ROLES.EMPLOYEE],
        });
      }

      return this.filterQuickLinks(QUICK_LINKS);
    },
    menu() {
      return this.$configuration.orderTypesEnabled
        ? this.orderTypesMenu
        : this.$configuration.mainMenu;
    },
    circularLinkText() {
      return this.$configuration.circularLinkText
        ? this.$configuration.circularLinkText
        : this.$t('weeklyAd');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
@import '~@/assets/styles/_settings.scss';

.side-menu-list,
.unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.side-menu-list {
  li {
    &:first-child {
      border-top: 0;
    }
  }
}
.social-icons {
  width: 100%;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    padding: rem(4px);
    margin: 0.3rem;
    border-radius: 50%;
    background-color: var(--dark);
    color: var(--white);
  }
  .facebook-icon {
    background: #3b5998;
  }
  .twitter-icon {
    background: #000000;
  }
  .youtube-icon {
    background: #ff0000;
  }
  .linkedin-icon {
    background: #0e76a8;
  }
  .instagram-icon {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
      radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
      radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
      radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
      radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
      radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
      radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
      linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  }
}

.side-menu-nav {
  color: var(--dark);
  border-top: 0.6rem solid var(--gray-200, $gray-200);
}
.icon-menu--margin {
  margin-left: $margin-3 * 0.8;
}
.icon-text {
  display: flex;
  align-items: center;
}
.sub-menu-list {
  padding: $pad-3 * 0.75 $pad-3 * 1.125;
  border-top: 0;
  border-bottom: 1px solid var(--gray-300);
}
.shopping-list--padding {
  padding: $pad-3 * 0.75 $pad-3 * 1.125 0;
}
.user-name--padding {
  padding: $pad-3 * 0.75 $pad-3 * 1.125;
}
.side-menu-icon {
  color: var(--primary, $primary);
}
.menu__text--color {
  color: var(--dark);
}
.wc-sidebar {
  padding-bottom: $mobile-body-padding-bottom;
}
</style>
