export default {
  "AcceleratedCheckOutFeatureUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not available"])},
  "AcceleratedCheckOutFeatureUnavailableSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Store Manager"])},
  "AcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerated Check Out Mode"])},
  "AcceleratedCheckOutVerifyStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start using Accelerated Checkout, verify the store you are shopping in."])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
  "addAsFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add ", _interpolate(_named("name")), " as favorite"])},
  "addCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Coupons"])},
  "addedSugars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Sugars"])},
  "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Item"])},
  "addItemsToOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add items to order"])},
  "addItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add item to list"])},
  "addItemToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add This Item to a List"])},
  "addNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new card"])},
  "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new list"])},
  "addNoteAboutItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a note about this item"])},
  "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Payment Method"])},
  "addPromotions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Promotions"])},
  "addSelectedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Selected to Cart"])},
  "addSelectedToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Selected to List"])},
  "addSpecialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Special Instructions"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Cart"])},
  "addToCurrentCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Current Cart"])},
  "addToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Favorite"])},
  "addToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to List"])},
  "addToWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Card to Wallet"])},
  "addWalletCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Wallet Card"])},
  "addWalletCardFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to save your card at this time"])},
  "addWalletCardSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Card has been successfully added"])},
  "addWhatCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What card type would you like to add?"])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
  "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Categories"])},
  "allOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Orders"])},
  "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
  "allowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow camera permission"])},
  "allowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow substitutions"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " ECRS - All rights reserved"])},
  "alreadySignedUpInStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already signed up for an account in store?"])},
  "alreadyVerifiedYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already verified your account?"])},
  "alternateItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternate Items"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "amountChargedWithCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount Charged to EBT Card Ending in ", _interpolate(_named("finalDigits"))])},
  "amountMustBeAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount must be at least ", _interpolate(_named("minAmount"))])},
  "amountMustBeLessOrEqual": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount must be less than or equal to ", _interpolate(_named("maxAmount"))])},
  "amountPerServing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Per Serving"])},
  "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "applyChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Changes"])},
  "applyEbtPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply EBT Payment"])},
  "approximateEach": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["about ", _interpolate(_named("price")), "/each"])},
  "areYouSureCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel your changes?"])},
  "areYouSureCancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel your order?"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the card ending in *"])},
  "areYouSureDeleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
  "areYouSureDeleteEbtCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete your EBT Card ending in ", _interpolate(_named("ending")), "?"])},
  "assistanceInReactivatingMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for assistance in reactivating your membership."])},
  "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate"])},
  "associateAsCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate as customer"])},
  "associationBanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Customer Association mode: Using WebCart as ", _interpolate(_named("name"))])},
  "assumesNoLiability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assumes no liability for inaccuracies or misstatements about products."])},
  "asYouScanItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As you scan items from your list we'll check them off for you"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "authorizationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization Code"])},
  "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Balance"])},
  "averagePerUnitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average per unit price"])},
  "averagePriceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price may vary slightly when item is weighed."])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "balanceCheckFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to check your balance at this time"])},
  "balanceDisplayDisclaimerOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We'll display your balance for ", _interpolate(_named("timeout")), " minutes, but you can check your balance again at any time. The info button ("])},
  "balanceDisplayDisclaimerTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([") will tell you when your balance was last checked."])},
  "barcodeAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode"])},
  "barcodePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display this barcode to scan at register"])},
  "beforeCheckOutPayFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you check out, please go to your account and pay on the membership details page."])},
  "beforeYouCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you cancel your order, please note the following:"])},
  "beforeYouCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you check out, "])},
  "beforeYouGoSaveItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you go, would you like to save these items to a list so you can find them easily again?"])},
  "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
  "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breadcrumb"])},
  "browseByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BROWSE BY CATEGORY"])},
  "browseQueryText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseText")), "\""])},
  "browseResultQuery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseResult")), "\""])},
  "buyItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy It Again"])},
  "buyItAgainItemsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items currently available"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
  "callForPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call for Price"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Us"])},
  "calories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calories"])},
  "cameraInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your camera will go to sleep while you are not actively scanning to conserve energy. Tap anywhere in this box to resume scanning."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "cancelEditOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel editing your order"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
  "cancelOrderFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We ran into a problem cancelling your order. Please refresh the page and try again."])},
  "cancelOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been cancelled."])},
  "cancelQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order?"])},
  "cannotAddItemToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot Add Item to Cart"])},
  "cannotCheckMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Cannot check membership status at this time."])},
  "cantFindSomething": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't Find Something?"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["card"])},
  "cardEnding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Ending"])},
  "cardEnteredInvalidInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The card number you entered is invalid or inactive. Check the number and try entering it again."])},
  "cardholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder Name"])},
  "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
  "cardNumberIsNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number is not valid"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
  "cartNotEligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The items in your cart are no longer eligible to be checked out."])},
  "cartOutdatedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The operation failed to complete because your cart is out of date. Please try again."])},
  "cartsLeftMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("cartsCount")), " ", _interpolate(_named("cartString")), " left to checkout"])},
  "cashierApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashier Approval Required"])},
  "cashierApprovalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item requires cashier approval. When you are ready to pay, please present this item to your cashier."])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "categoriesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories Menu"])},
  "categoryLimitExceededMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You cannot choose more than ", _interpolate(_named("limit")), " in the ", _interpolate(_named("categoryName")), " category."])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "changeYourMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your mind?"])},
  "changeYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Your Password"])},
  "chargeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Accounts"])},
  "checkBackShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later or contact the store."])},
  "checkBackShortlyInStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later or contact an employee in the store."])},
  "checkBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Balance"])},
  "checkCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Card"])},
  "checkEbtBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check EBT Balance"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
  "checkoutNextOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout your next order"])},
  "checkShoppingAtCorrectStore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are shopping at ", _interpolate(_named("storeName")), ". Make sure this is the store you want to order from."])},
  "checkTheBoxForReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the box to receive your receipts from in-store purchases via email."])},
  "checkTheGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the groups which you want to belong."])},
  "checkYourBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Your Balance"])},
  "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose ", _interpolate(_named("max"))])},
  "chooseACategoryMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a category menu"])},
  "chooseAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a List"])},
  "chooseAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an Account"])},
  "chooseAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose at least ", _interpolate(_named("min"))])},
  "chooseAWeightedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a Weighted Quantity"])},
  "chooseBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose between ", _interpolate(_named("min")), " and ", _interpolate(_named("max"))])},
  "chooseCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Cart"])},
  "chooseDataAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a Date and Time"])},
  "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose date"])},
  "chooseDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Date and Time"])},
  "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Language"])},
  "chooseQuantityFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose quantity for"])},
  "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose time"])},
  "chooseUpTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose up to ", _interpolate(_named("max"))])},
  "chooseYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose your ", _interpolate(_named("category"))])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
  "clearAllCarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to clear all your carts? This will clear all items in all carts!"])},
  "clearAllText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to clear your cart?"])},
  "clearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Cart"])},
  "clearCartFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to clear your cart"])},
  "clearCarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All Carts"])},
  "clearCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart has been successfully cleared"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Filters"])},
  "clearOrSaveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to save these items for later or clear your cart?"])},
  "clearYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Your Cart"])},
  "clickHereToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here to pay."])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to register"])},
  "clickHereToReregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to re-register"])},
  "clickHereToSendAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to send another"])},
  "clickHereToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to sign in"])},
  "clickTheVerificationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the verification link in the email to finish registering your account."])},
  "clickToAddNotesAndSubstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add notes and substitutions"])},
  "clipCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clip Coupon"])},
  "clippedCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipped"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "closeShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close shopping list"])},
  "closeShoppingListContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close your shopping list "])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
  "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "completeAtCashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete at a cash register"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "completedYourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Your Order"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "contactlessDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactless Delivery?"])},
  "contactTheStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact the store"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "continueHereAndEndAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue here and end Accelerated Check Out"])},
  "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Shopping"])},
  "continueShoppingHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to shop here"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
  "couldNotProcessShipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not process shipment"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "couponAndPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions & Coupons"])},
  "couponApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Applied"])},
  "couponAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "couponCanBeAppliedOnlineAndStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon can be applied to both in-store and online transactions"])},
  "couponClipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipped"])},
  "couponCodeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon code sucessfully applied to transaction."])},
  "couponDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Details"])},
  "couponDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Details Page"])},
  "couponNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find this coupon. It may be expired or unavailable."])},
  "couponRedeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeemed"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
  "createANewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a New List"])},
  "createAPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Password"])},
  "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a New Password"])},
  "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
  "creditCardInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card Input"])},
  "creditCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Cards"])},
  "creditDebitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit/Debit Card"])},
  "creditLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Limit"])},
  "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
  "currentAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(current)"])},
  "currentlySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Selected"])},
  "currentlyShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Shopping"])},
  "currentSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Selections"])},
  "currentSpecials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Specials"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
  "customAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Amount"])},
  "customerAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Association"])},
  "customerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER CARD"])},
  "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer ID"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Info"])},
  "customerPrograms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Programs"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
  "customizeAndAddToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize & Add To List"])},
  "customizeListItemToAddToCartAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Customize your ", _interpolate(_named("itemName")), " before adding to cart."])},
  "customizeYourItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Customize Your ", _interpolate(_named("itemName"))])},
  "customPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom price"])},
  "datePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Picker"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "decreaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease quantity"])},
  "decrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrement"])},
  "defaultInitiateErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to connect to EBT Provider. Please try again later, it may be down temporarily."])},
  "defaultToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to install this app on your home screen for quick access?"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Card"])},
  "deleteCardSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your Card ending with ", _interpolate(_named("finalDigits")), " has been successfully deleted"])},
  "deleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete List"])},
  "deleteWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Wallet"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
  "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Delivery Details"])},
  "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Fee"])},
  "deliveryInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Instructions for Driver (Optional)"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "descriptionTooLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be 256 characters or shorter."])},
  "descriptionTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must have a description."])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "deviceNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device not supported"])},
  "didntReceiveEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the email?"])},
  "didYouMean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you mean:"])},
  "digitalCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Coupons"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
  "disclaimerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While we work to ensure that product information is correct, on occasion manufacturers may alter their ingredient lists. Actual product packaging and materials may contain more and/or different information than that shown on our Web site. We recommend that you do not solely rely on the information presented and that you always read labels, warnings, and directions before using or consuming a product. For additional information about a product, please contact the manufacturer. Content on this site is for reference purposes and is not intended to substitute for advice given by a physician, pharmacist, or other licensed health-care professional. You should not use this information as self-diagnosis or for treating a health problem or disease. Contact your health-care provider immediately if you suspect that you have a medical problem. Information and statements regarding dietary supplements have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease or health condition."])},
  "discountAppliedInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount applied in the cart."])},
  "discountIncludedAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal includes all applicable discounts"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
  "distanceToStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to store"])},
  "doesNotIncludeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does Not Include Tax or Discounts"])},
  "dollarSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "doNotAllowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not allow substitutions"])},
  "downloadLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Logs"])},
  "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drag"])},
  "dragContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand product container by drag"])},
  "dropoffAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropoff Address"])},
  "eachAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ea"])},
  "ebtAmountRefundedToCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount Refunded to EBT Card Ending in ", _interpolate(_named("ending")), ":"])},
  "ebtBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Balance"])},
  "ebtCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Card"])},
  "ebtCardDeleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["EBT Card ending in ", _interpolate(_named("ending")), " deleted successfully!"])},
  "ebtCardDeletedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your applied EBT Payment was removed and refunded because you deleted your card. If desired, please add a new EBT Card and apply a new EBT Payment in Checkout."])},
  "ebtCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Cash"])},
  "ebtCashRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Cash Refund:"])},
  "ebtCashRemainingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining EBT Cash Balance"])},
  "ebtEstimatedBalanceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The EBT SNAP Balance above reflects an estimated balance and could change once you continue to check out. No deduction takes place until you proceed to checkout and complete your order."])},
  "ebtPaymentApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Payment applied successfully!"])},
  "ebtPaymentNotApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Payment was not Applied!"])},
  "ebtPaymentRefundedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Payment refunded!"])},
  "ebtPaymentRefundedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Payment Refunded"])},
  "ebtPinDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll be required to enter your EBT PIN."])},
  "ebtRemainingBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remaining ", _interpolate(_named("type")), " Balance:"])},
  "ebtRemovePaymentSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT Payment removed successfully!"])},
  "ebtRemovePaymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove EBT Payment"])},
  "ebtSnap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT SNAP"])},
  "ebtSnapBeginningBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT SNAP Beginning Balance"])},
  "ebtSnapDeductions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT SNAP Deductions"])},
  "ebtSnapRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT SNAP Refund:"])},
  "ebtSnapRemainingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining EBT SNAP Balance"])},
  "ebtTotalDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips and fees cannot be paid for with this tender"])},
  "ebtTotalsRefundedToCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["EBT Total(s) refunded to EBT Card ending in ", _interpolate(_named("ending")), ":"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "editExpiredHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, times up!"])},
  "editExpiredMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your available time to edit your order has been exceeded, but don't worry! Your original order will still submitted to our store for fulfillment. Please allow up to five minutes for your original order to display in your Order History. Note that when your order displays in Order History, you may be able to edit it again (if time allows)."])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Item"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Order"])},
  "editOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed! Your order was not edited."])},
  "editOrderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit order?"])},
  "editOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order is ready for editing. When you are done adding or removing items, please proceed to checkout again"])},
  "editOrderWarning": {
    "cancelPoint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have completed your edits, you'll need to fully checkout again, <strong>as you won't receive your items unless you do so</strong>. Don't worry, your chosen payment method(s) will not be charged twice."])},
    "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to edit your order?"])},
    "deliveryPoint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your order was to be delivered by DoorDash, you must choose a new delivery date and time; your original selection will not be preserved."])},
    "ebtPoint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you decide to edit your order, any EBT Total(s) applied to this order will be refunded to your EBT Card."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you edit your order, please note the following:"])},
    "safePoint1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To ensure our store has enough time to prepare your order, you will have <strong>", _interpolate(_named("editTimeMinutes")), " minutes</strong> to complete your edits."])},
    "safePoint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have completed your edits, you'll need to fully checkout again. Don't worry, your chosen payment method(s) will not be charged twice."])},
    "safePoint3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't complete your edits in time, your original order will still be sent to the store for fulfillment."])}
  },
  "editYourNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your note"])},
  "eGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Card"])},
  "eGiftCardBuild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build Your E-Gift Card"])},
  "eGiftCardCartAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Card Added to Cart"])},
  "eGiftCardCartAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An E-Gift Card has been successfully added to your cart"])},
  "eGiftCardCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items need to be purchased separately from the E-Gift Card in your cart. Please remove or purchase your E-Gift Card before you begin adding items to your cart."])},
  "eGiftCardFormMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a message about this E-Gift Card (optional)"])},
  "eGiftCardModalAlertShoppingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish shopping before buying an E-Gift Card."])},
  "eGiftCardModalAlertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Cards must be purchased separately from your cart items"])},
  "eGiftCardPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Our E-Gift Card Policy"])},
  "eGiftCardPolicyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Card Policy"])},
  "eGiftCardPurchaseAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Cards need to be purchased separately from the items in your cart. Please remove or purchase those items before you begin creating an E-Gift Card."])},
  "eGiftCardShippingAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Cards are delivered electronically (email and/or text) or downloaded (for printing). See Step 1 to review the method you selected"])},
  "eligibleItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible Items"])},
  "eligibleTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible Total"])},
  "eligibleUPCs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible UPCs"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "emailInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address is already associated with an account."])},
  "emailInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address is already in use"])},
  "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
  "emailPhoneOrAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail, Phone, or Account Number"])},
  "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email To"])},
  "employeeActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Actions"])},
  "employeeDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Dashboard"])},
  "employeeLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Login"])},
  "endingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ending in"])},
  "enterAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently in Accelerated Check Out mode"])},
  "enterEbtInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your EBT card information"])},
  "enterGiftCardPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Gift Card PIN"])},
  "enterInstructionsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter special instructions for your ", _interpolate(_named("itemName"))])},
  "enterStoreLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a store from dropdown menu."])},
  "enterTheWrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the wrong email?"])},
  "entireBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entire Balance:"])},
  "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
  "equityNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity Needed:"])},
  "equityPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity Paid:"])},
  "equityPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity Payment"])},
  "equityPaymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity Payment:"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error(404)"])},
  "errorInAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Accelerated Check Out mode"])},
  "errorInCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in cancel payment"])},
  "errorInPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in payment"])},
  "errorScanProductNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanned product information not found, please contact store manager"])},
  "estimatedDropoffDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Dropoff Date"])},
  "estimatedDropoffTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Dropoff Time"])},
  "estimatedSnapBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated SNAP Balance"])},
  "estimatedSnapBalanceDeductionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deduction takes place until you checkout."])},
  "estimatedSnapTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated SNAP Total"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
  "exitAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Accelerated Check Out"])},
  "exitAcceleratedCheckOutModeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To begin online shopping here, your mobile Accelerated Check Out session will end."])},
  "exitAcceleratedCheckOutModeModalBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items scanned using Accelerated Check Out cannot be added to your cart once you exit Accelerated Check Out. Would you like to move these items to a shopping list?"])},
  "exitAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exited from Accelerated Check Out Mode"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
  "expirationAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp."])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires"])},
  "expiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiring"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "failedPasswordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reset password"])},
  "failedVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed verification"])},
  "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorite"])},
  "featureNotAvailableForDesktopWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not available for desktop"])},
  "featureNotAvailableForMobileWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not available for mobile"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee"])},
  "feePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Payment"])},
  "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
  "feesPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees Paid:"])},
  "feesPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees Payment:"])},
  "fieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
  "findOurStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find our Store"])},
  "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a Store"])},
  "finishCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Customization"])},
  "firefoxAndroidToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to install this app on your home screen for quick access? - Tap on the home icon and then Add to Home Screen."])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "firstNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name is required"])},
  "forAssistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for assistance."])},
  "forgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "forText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["for ", _interpolate(_named("name"))])},
  "fraudAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
  "fraudAlertLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order could not be placed because the card was declined."])},
  "fraudAlertLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select <strong>OK</strong> to close this message and try a different payment method."])},
  "fraudCommErrorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
  "fraudCommErrorLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order could not be placed because the card verification service could not be reached."])},
  "fraudCommErrorLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select <strong>OK</strong> to close this message to try again or to use a different payment method."])},
  "fraudUnknownBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error encountered while processing payment"])},
  "fraudUnknownHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to process payment"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "getCodeViaEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a code via email."])},
  "giftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Card"])},
  "giftCardBalanceCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Your Gift Card Balance"])},
  "giftCardCartAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a E-Gift Card in your cart. Please purchase the E-Gift Card or remove it from your cart."])},
  "giftCardCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a Gift Card?"])},
  "giftCardCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Card Code:"])},
  "giftCardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Card Details"])},
  "giftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Card"])},
  "giftCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Gift Cards"])},
  "giftCardWithIdImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-Gift Card Design ", _interpolate(_named("id"))])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
  "goGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go green with paperless receipts"])},
  "goLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go left"])},
  "goodThrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Through: "])},
  "goRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go right"])},
  "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got It"])},
  "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Checkout"])},
  "goToHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to home store"])},
  "goToNextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to next month"])},
  "goToPreviousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to previous month"])},
  "goWithPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go green with paperless receipts"])},
  "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand Total"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have an account?"])},
  "haveCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have coupons?"])},
  "haveYouPaidForYourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you finished paying at the register?"])},
  "healthAttributeImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Health attribute ", _interpolate(_named("attributeName"))])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "hideEbtDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide EBT Details for your order"])},
  "hideEligibleItems": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["View ", _interpolate(_named("tender")), " Eligible Items"])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Password"])},
  "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
  "homeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home store"])},
  "homeStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Home Store)"])},
  "howManyWouldYouLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many would you like?"])},
  "hprRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Picks for You"])},
  "idRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Required"])},
  "idRequiredMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you're ready to pay, please take this item to a cashier and show your ID."])},
  "ifThisCardHasBeenUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this card has been used as a payment method for any unplaced orders currently in checkout, any applied EBT payment will be removed (and funds will be returned to the card) when you delete this card from your Wallet."])},
  "ifYouCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(If you check out)"])},
  "imHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm here!"])},
  "importantCartMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important message about items in your cart"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In"])},
  "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes"])},
  "increaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase Quantity"])},
  "increment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increment"])},
  "infoRequiredSingleOrMultiItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information required: Is this a single item or a pack containing multiple items?"])},
  "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
  "ingredientsCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGREDIENTS"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "installFromSafari": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add this to your homepage, visit us in Safari."])},
  "inStoreOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Store Only"])},
  "inStoreOnlyAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " can only be bought in store"])},
  "invalidField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Field"])},
  "inYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in your cart"])},
  "iosShareMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tap on the "])},
  "iosShareMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" share icon and select 'Add to Home Screen'."])},
  "iosToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install this application on your home screen for quick access when you want to shop again."])},
  "isNowOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is now out of stock and has been removed from your cart"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item"])},
  "itemAddedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Added to Cart"])},
  "itemAddedToSaveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Added To Save For Later"])},
  "itemDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Details"])},
  "itemNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Notes"])},
  "itemPack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Pack"])},
  "itemProhibitAlternativeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This cannot be removed"])},
  "itemProhibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Prohibited"])},
  "itemProhibitedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is not available for purchase right now. If you have questions, please ask a store employee."])},
  "itemQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item-quantity"])},
  "itemRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Restricted"])},
  "itemRestrictedCartMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart has a restricted item and will require you to checkout at the register."])},
  "itemRestrictedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item has a restriction and will require checkout at the register."])},
  "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])},
  "itemScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Scanned"])},
  "itemsPurchasedMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You purchased ", _interpolate(_named("itemsCount")), " ", _interpolate(_named("itemString")), " on ", _interpolate(_named("orderTime"))])},
  "itemTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Total"])},
  "itemUnavailableAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " is currently not available"])},
  "itLooksPickedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you have already picked up this order. If you have questions or concerns, please give us a call"])},
  "joinPromosAndRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our promotions and rewards program"])},
  "kilometersAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km away"])},
  "knowYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know your password?"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "lastNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name is required"])},
  "lastPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Payment:"])},
  "lessDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less Details"])},
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])},
  "listIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list is empty!"])},
  "listNamesCannotExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List names cannot exceed 20 characters"])},
  "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "locallySourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is locally sourced"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "loginFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to login"])},
  "loginFormSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back! Sign in for faster checkout and to be able to track and view your past orders."])},
  "loginFormSubheadingAcceleratedCheckOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome to ", _interpolate(_named("retailer")), "'s Accelerated Check Out powered by ECRS"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login ID"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "loyaltyExpirationAmountCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expire on"])},
  "loyaltyExpirationAmountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points expire on"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "makeAPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make A Payment"])},
  "makeASelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a selection"])},
  "makeEquityPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Equity Payment"])},
  "makeFeePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Fee Payment"])},
  "makeHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make this your home store"])},
  "manageSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your saved shopping list"])},
  "manageYourSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your saved list here"])},
  "memberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member ID"])},
  "membershipDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Details"])},
  "membershipInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Inactive"])},
  "membershipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Information"])},
  "membershipPaidSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership payment made successfully."])},
  "membershipPaymentDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Payment Due"])},
  "membershipPaymentIsDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your membership payment is due."])},
  "membershipProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership profile:"])},
  "membershipSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member since:"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "messageSentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has not been sent"])},
  "messageSentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been successfully sent"])},
  "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Name"])},
  "milesAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])},
  "minimumSelectionSatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum selection satisfied"])},
  "mobileAcceleratedCheckOutSessionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Accelerated Check Out Session in Progress"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "moreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Details"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Info"])},
  "moreTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more times."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "nameYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your List"])},
  "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help? Read this OLM article."])},
  "needsMinimumSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Needs minimum selection of ", _interpolate(_named("min"))])},
  "needToStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to start over?"])},
  "newCashBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New EBT Cash Balance:"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "newSnapBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New EBT SNAP Balance:"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "nextPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Payment:"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancel"])},
  "noCancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancel Delete"])},
  "noCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any Credit Cards in your wallet."])},
  "noClearMyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, just clear my cart"])},
  "noContactInfoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact information available."])},
  "noContinueToOrderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Continue to Order Summary"])},
  "noCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any available eCoupons"])},
  "noCustomerAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Customers are currently available"])},
  "noDeleteMyItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Delete My Items"])},
  "noDigitalCoupons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You do not have any ", _interpolate(_named("couponType")), " coupons, please try again later."])},
  "noDontDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Don't Delete"])},
  "noEligibleProductsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Eligible Products Found"])},
  "noGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Groups Found"])},
  "noItemsOnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Items were on this Order."])},
  "noLongerActiveMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! You are no longer an active member."])},
  "noMembershipFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Membership Found"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders currently available."])},
  "noProductImageAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " product image unavailable"])},
  "noPromotionsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No promotions are available for your order"])},
  "noRemoveMyItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Remove My Items"])},
  "noReservationTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservation times are available for this order type."])},
  "noSavedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No saved cards"])},
  "noSearchResultsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No results for \"", _interpolate(_named("name")), "\""])},
  "noShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Shopping list found"])},
  "noStoresFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No stores found."])},
  "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
  "notAvailableAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "notAvailableOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available online"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
  "noteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note Added"])},
  "notEntered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No ", _interpolate(_named("name")), " entered"])},
  "noThankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Thank You"])},
  "notOnYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not on your list"])},
  "notSignedUpYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not signed up yet?"])},
  "notYourHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not your home store"])},
  "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutrition"])},
  "nutritionFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutrition Facts"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
  "okayGotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay, got it!"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
  "onceYouCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you cancel your order, you will receive a refund to your EBT Card in the amount of EBT Total(s) applied to this order."])},
  "onlyNeedToSetPasswordPleaseClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! All you need to do is set your password to start using the site; no need to create a new account. To set your password, please click "])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
  "openToCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open To Charge"])},
  "operaBlockedVersionWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app is not supported in Opera version 9 to version 12. Please use other browsers."])},
  "operaMiniToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to install this app on your home screen for quick access? - Tap on the plus icon and then Add to Home Screen."])},
  "optInToAllowInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt-in to allow us to send you information about upcoming promotions, receive coupons, and more."])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "orderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled the Order"])},
  "orderCancelledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed! Your order was not cancelled."])},
  "orderCancelledSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order ", _interpolate(_named("orderScanCode")), " has been cancelled."])},
  "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
  "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
  "orderItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Items"])},
  "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Notes"])},
  "orderNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order is not valid"])},
  "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Placed"])},
  "orderPlacedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order can't be placed"])},
  "orderPlacedErrorVerbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! We are having trouble placing your order right now."])},
  "orderProcessedMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order #", _interpolate(_named("scancode")), " is being processed"])},
  "orderProcessingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your purchase can't be processed"])},
  "ordersInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders in Progress"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
  "orderTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Total"])},
  "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Type"])},
  "orderTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Types"])},
  "otherSearchSuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other search suggestions"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Stock"])},
  "outOfStockAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " is currently out of stock"])},
  "packOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pack of"])},
  "packSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pack Size"])},
  "pageNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page navigation"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "passwordMustInclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must include"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
  "passwordRequirementsNotMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password requirements not met"])},
  "passwordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not validate your identity based on the provided account details."])},
  "passwordValidationCharLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least eight characters"])},
  "passwordValidationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one number"])},
  "passwordValidationSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one symbol"])},
  "passwordValidationUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one UPPERCASE letter"])},
  "pauseAutoplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause autoplay"])},
  "payAtRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at register"])},
  "payFullEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Equity In Full:"])},
  "payLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Later"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "paymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
  "paymentDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Due"])},
  "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "payMinEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Minimum Equity:"])},
  "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
  "payOnYourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay on your phone"])},
  "payTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Total"])},
  "percentDailyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Daily Value*"])},
  "percentDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet, 2,000 calories a day is used for general nutrition advice."])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "phoneNumberInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number is already associated with an account."])},
  "phoneNumberInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number is already in use"])},
  "phoneNumberIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is required"])},
  "phoneOrAppMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone or app missing?"])},
  "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])},
  "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please"])},
  "pleaseAllowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please allow your camera to scan the barcode"])},
  "pleaseEnterDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Item Description"])},
  "pleaseEnterValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid amount to tender"])},
  "pleaseEnterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter your ", _interpolate(_named("name"))])},
  "pleaseRegisterAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register again to get a new link."])},
  "pleaseScanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan your next item"])},
  "pleaseSelectAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a list"])},
  "pleaseSelectAtLeastOneDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one method for delivery"])},
  "pleaseSelectDelDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a delivery date and time"])},
  "pleaseTryAgainLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "pricePerUnit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Price per ", _interpolate(_named("unit"))])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "printReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Receipt"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
  "productDescImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " Product, see description in page"])},
  "productDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Details Page"])},
  "productImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " ", _interpolate(_named("productName")), " Product"])},
  "productImgGalleryThumbnailAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " Product Gallery Thumbnail"])},
  "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product information"])},
  "productMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production Method"])},
  "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
  "profileAddressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Information"])},
  "profileEmailSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Information"])},
  "profileOtherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Information"])},
  "profilePersonalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
  "profilePhoneSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Information"])},
  "profileWorkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Information"])},
  "qrcodeScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code Scanned"])},
  "qrcodeScannedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have scanned a QR code, would you like to open the link?"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "quantityAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QTY"])},
  "quantitySlashWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity / Weight"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Links"])},
  "readyForDasher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for Dasher"])},
  "readyToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Redeem!"])},
  "receiptLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt loading..."])},
  "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
  "recipientsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's Email Address"])},
  "recipientsPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's Phone Number"])},
  "recommendComplexPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend you create a complex password that is different than a password you have used in the past."])},
  "redeemAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem at"])},
  "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting..."])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "registerAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Again"])},
  "relatedCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Coupons"])},
  "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "reloadAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload Amount"])},
  "reloadChargeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload Charge Account"])},
  "remainingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Total"])},
  "remainingTotalRefunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The remaining total of your order has been refunded to the respective payment method(s) applied to this order."])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove All"])},
  "removeAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove All Items"])},
  "removeCartAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use Accelerated Check Out, the items currently in your cart will be removed. Would you like to move your items to a shopping list?(E-Gift Cards cannot be moved to a list)"])},
  "removeCartAcceleratedCheckOutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Cart Items"])},
  "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("name")), " from Favorites"])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from Cart"])},
  "removeFromCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from Checkout"])},
  "removeFromFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from filter"])},
  "removeFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from List"])},
  "removeGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed Gift card"])},
  "removeGiftCardCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed Gift card from cart"])},
  "removeItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Items"])},
  "removePromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Promotion"])},
  "removeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Selected"])},
  "removeTheItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove the items"])},
  "replicateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replicate Now"])},
  "requestedDropoffDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Dropoff Date"])},
  "requestedDropoffTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Dropoff Time"])},
  "requestRemovalDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completing this action will delete the account you use online AND in-store. This process may take time as your account will be reviewed before deletion. Until then, you can continue to use this account."])},
  "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])},
  "resetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Method"])},
  "resetNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Now"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "resetPasswordAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you created an account in the store, you may need to set your password to shop online for the first time"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
  "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Home"])},
  "reviewYourItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Your Items"])},
  "reviewYourOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Your Orders"])},
  "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards"])},
  "roundUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RoundUp"])},
  "roundUpDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on your final order total after order preparation, your round up amount may vary, but the round up amount will never exceed $1.00."])},
  "roundUpPrompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Round up to ", _interpolate(_named("total")), " to support a worthy cause?"])},
  "runMobileAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run your mobile authenticator app to get a six-digit code"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])},
  "salePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Price"])},
  "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "saveAllItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save All Items For Later"])},
  "saveAllItemsToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save All Items to List"])},
  "saveAllItemsToListText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All items in your cart will be removed. Would you like to move these items to a shopping list for future use?"])},
  "saveBeforeClearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to save your items to a list before clearing your cart?"])},
  "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Card"])},
  "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Cards"])},
  "savedForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved For Later"])},
  "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save For Later"])},
  "saveItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save items for later"])},
  "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan"])},
  "scanAnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan an item"])},
  "scanBarcodeCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this barcode to use your customer card"])},
  "scanCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan Customer Card (In Store)"])},
  "scanError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item not found"])},
  "scanItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan an item"])},
  "scanItemInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As you scan items from your list we'll check them off for you"])},
  "scanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan your next item"])},
  "scanningError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanning Error"])},
  "scanningErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid QR Code"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "SearchByLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Current Location"])},
  "searchByZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by zip code"])},
  "searchCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Customers..."])},
  "searchFilterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search category items"])},
  "searchProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Program"])},
  "searchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results for"])},
  "securelyTokenizedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securely Tokenized Cards"])},
  "securityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Code"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
  "seePriceAtCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Price at Checkout"])},
  "seePriceInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Price in Cart"])},
  "selectADesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a design"])},
  "selectAnAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an amount"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a country"])},
  "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Day"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
  "selectedGiftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected E-Gift Card"])},
  "selectedToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected to Print"])},
  "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select hours"])},
  "selectImHereButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the 'I'm here' button when you arrive at the store, and we'll bring your order out."])},
  "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select minutes"])},
  "selectOneGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select exactly 1 group to which you want to belong."])},
  "selectOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select order type"])},
  "selectPasswordResetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! Select the method (phone or email) that you provided in-store to receive your password verification code."])},
  "selectPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select how you would like to pay for your items:"])},
  "selectProductManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select product manually"])},
  "selectQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select quantity"])},
  "selectShippingOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Shipping Option"])},
  "selectShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select shopping list"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a State"])},
  "selectStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Your Store"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time"])},
  "selectYourWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your weight"])},
  "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Date"])},
  "senderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Name"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Later"])},
  "sendLaterDatePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Later Date picker"])},
  "sendLaterTimePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Later time picker"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
  "sendNotificationsPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to receive text notifications from the delivery provider regarding the status of your order?"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Now"])},
  "sendOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send On"])},
  "sendUsAMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Us A Message"])},
  "servingSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serving Size"])},
  "servingsPerContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servings Per Container"])},
  "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Password"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
  "shippingEstimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Estimate:"])},
  "shippingGenericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've encountered an error. Please try again in a moment. If this keeps happening, please contact the store."])},
  "shippingMethodShippingDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you click Continue, you can select one of the available shipping options."])},
  "shippingProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Provider"])},
  "shippingRateLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experiencing high volume. Please try again in a minute."])},
  "shipTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship To"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "shopAnotherStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop at another store"])},
  "shopByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop By Category"])},
  "shopEbtItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop EBT-Eligible Items"])},
  "shopEligibleProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Eligible Products"])},
  "shopFromASavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop from a Saved List"])},
  "shoppingAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping at:"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Cart"])},
  "shoppingCartWithItemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Shopping cart with ", _interpolate(_named("count")), " items"])},
  "shoppingLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Lists"])},
  "shopStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop at this store"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "showCouponClipFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clip Coupon failed."])},
  "showCouponLoginPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Login as a customer to clip coupon."])},
  "showCouponLoginPromptCpbFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Login as a customer to filter coupons."])},
  "showCouponMoreInfoFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, more coupon information is not available at this time."])},
  "showHidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show/hide password"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
  "showingOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing orders"])},
  "showingResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing results for"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Password"])},
  "sideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side Menu"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "signInToShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In To Shop"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "singleItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Item"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
  "sorryCouponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the coupon code you entered cannot be applied to this transaction."])},
  "sorryCouponCodeNotValid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The coupon code ", _interpolate(_named("couponCode")), " is not valid."])},
  "sorryPaymentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we are unable to process your payment at this time. Please try again later."])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "sortCouponBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort coupons by"])},
  "sortProductBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort products by"])},
  "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Instructions"])},
  "specialOrderItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Order Item"])},
  "specialPaymentOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Payment Options"])},
  "specialPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Promos"])},
  "splitTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SplitTender"])},
  "stageCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage Completed"])},
  "stageIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage Incomplete"])},
  "stageInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage In progress"])},
  "startAutoplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start autoplay"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "stateProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State/Province"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
  "storeLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Location"])},
  "storeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Locations"])},
  "storeLogoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Logo"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Name"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Address"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apt/Suite (Optional)"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "substitutionAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitution Allowed"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "subtotalOfAllCarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal of All Carts"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "successChargeAccountReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully reloaded your charge account."])},
  "successOrderInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! Thank you, your order is now in progress."])},
  "successOrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! Your order has been placed."])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suffix"])},
  "suggestionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestions List"])},
  "suppressReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off paper receipts"])},
  "tapToScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tap to Scan"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "textTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text To"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
  "thankYouForCreatingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for creating an account! By doing so you'll be able to enjoy a faster checkout experience, tracking and viewing past orders, and more."])},
  "timePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Picker"])},
  "tipCustomAmountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Amount"])},
  "tipHighConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "tipHighTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large Tip"])},
  "tipHighUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "tipHighWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tip you selected is above 50%. Is this what you intended?"])},
  "tipNone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tip"])},
  "tipReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset tips"])},
  "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips"])},
  "tipsApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips applied! See order summary"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "toastInstallBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install"])},
  "toastNotNowBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Now"])},
  "toastOkBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "toCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "toContinueUsingAcoPayMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To continue using Accelerated Checkout, please pay your membership."])},
  "tokenServerUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The secure token server is currently not available. Please try again later."])},
  "topResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP RESULTS"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "totalNotCoveredBySnap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal Not Covered by SNAP"])},
  "totalsChargedToEbtCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totals charged to EBT card ending in"])},
  "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "twoFactorVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Verification"])},
  "typeListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type list name"])},
  "unassociate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassociate"])},
  "unassociateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully been unassociated"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
  "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
  "verificationLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry but this verification link has expired"])},
  "verifyingYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying your account..."])},
  "verifyOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Order Type"])},
  "verifyOrderTypeFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please verify the order type for ", _interpolate(_named("item"))])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
  "viewAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Items"])},
  "viewCouponOnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View coupon applied to this item"])},
  "viewDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Delivery"])},
  "viewEbtDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View EBT Details for your order"])},
  "viewEGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View E-Gift Card"])},
  "viewEligibleItems": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hide ", _interpolate(_named("tender")), " Eligible Items"])},
  "viewItemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Item List"])},
  "viewManageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View/Manage List"])},
  "viewManageShoppingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["View or manage ", _interpolate(_named("shoppingListName")), " list"])},
  "viewMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Map"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
  "viewNextDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View next set of available dates"])},
  "viewNextTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View next set of available times"])},
  "viewPrevDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View previous set of available dates"])},
  "viewPrevTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View previous set of available times"])},
  "viewReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Receipt"])},
  "viewStoreLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View store locations"])},
  "viewYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your"])},
  "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])},
  "waitItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait! There are items in your cart."])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "webCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebCart"])},
  "weCouldntFindThisItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find this item. It may be disabled or unavailable."])},
  "weCouldntFindWhatYoureLookingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find what you're looking for."])},
  "weeklyAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Ad"])},
  "weHaveBeenNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have been notified that you are here."])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "weightedItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight Required"])},
  "weightedItemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item needs to be weighed by a cashier. When you are ready to pay for your items, your cashier will weigh these."])},
  "weightedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weighted Quantity"])},
  "weJustSentAVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We just sent a verification link to your email"])},
  "weNeedToSendVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We need to send you a verification code. How would you like to receive it?"])},
  "wereOnOurWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're on our way"])},
  "whenFinishedEditCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you are finished editing your order, you will need to continue to checkout again"])},
  "whenYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you arrive"])},
  "whereWouldYouLikeToShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where would you like to shop today?"])},
  "willBeSentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will be sent on"])},
  "wouldYouLikeToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to add these items to the order you're about to edit or save them for later?"])},
  "xForY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("x")), "/", _interpolate(_named("y"))])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "yesClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Close"])},
  "yesContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Continue"])},
  "yesDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Delete"])},
  "yesDeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Delete the card ending in *"])},
  "yesDonePaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I'm Done Paying"])},
  "yesMoveMyItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Move My Items"])},
  "yesPlease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, please!"])},
  "yesSaveItemsToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Save Items to a List"])},
  "youAreShoppingHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently shopping here:"])},
  "youCanEither": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can either"])},
  "youCanStillShopAsGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can still shop as a guest, if desired."])},
  "youCanUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use this promotion"])},
  "youCanUsePromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use these promotions now or save them for later."])},
  "youDontHaveChargeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any charge accounts."])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
  "youHaveAlreadyNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already successfully notified us that you are here. If you are experiencing an unusually long delay, please give us a call"])},
  "yourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account"])},
  "yourCancelledOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Cancelled Orders"])},
  "yourCartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty"])},
  "yourCompletedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Completed Orders"])},
  "yourCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your eCoupons"])},
  "yourCredentialsMatchMultipleAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credentials match multiple accounts, please select which account to login as"])},
  "yourCustomerDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Customer Discounts"])},
  "yourEbtBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your Balance (Card# ", _interpolate(_named("maskedCardNum")), ")"])},
  "yourID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ID: "])},
  "yourMemberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Member ID:"])},
  "yourPunchCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ePunch Cards"])},
  "yourVerificationCodeSentShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have an account with us your verification code will be sent shortly using the provided contact method."])},
  "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
  "zipCodeInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid zip code"])},
  "zipCodePostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip/Postal Code"])},
  "zipCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid zip code"])}
}